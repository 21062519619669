/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { Button } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

const SkipToContentButton = styled(Button)`
  opacity: 0;
  pointer-events: none;
  text-decoration: none;
  position: absolute;
  font-weight: initial;
  letter-spacing: initial;
  text-transform: none;
  color: ${themeGet('colors.greys.charcoal')};
  background-color: ${themeGet('colors.white')};

  z-index: ${themeGet('zIndices.dropdownContent')};
  padding: 2px ${themeGet('space.1')};
  left: ${themeGet('space.2')};
  top: ${themeGet('space.2')};

  &:focus {
    opacity: 1;
    pointer-events: auto;
  }
`;

export default SkipToContentButton;
