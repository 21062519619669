import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Box } from '@qga/roo-ui/components';

export const Menu = styled(Box)`
  user-select: none;
  min-width: 320px;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.soft')};
  z-index: ${themeGet('zIndices.modal')};
`;

export const FullScreenMenu = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.hard')};
  z-index: ${themeGet('zIndices.modal')};
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${themeGet('colors.greys.charcoal')};
  opacity: 0.8;
  z-index: ${themeGet('zIndices.modal')};
`;

export const StyledText = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${themeGet('space.2')};
`;
