import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const FocusManager = ({ onBlur, onClickOutside, onFocusOutside, wrapperComponent, children }) => {
  const ref = useRef();
  const focused = useRef(false);
  const Wrapper = wrapperComponent;

  const handleDocumentClick = useCallback(
    (event) => {
      if (!ref?.current) return;

      const isOutside = !ref.current.contains(event.target);
      const isCurrentlyFocused = focused.current;
      focused.current = !isOutside;

      if (isOutside && isCurrentlyFocused) {
        onBlur(event);
        onClickOutside(event);
      }
    },
    [onBlur, onClickOutside],
  );

  const handleDocumentFocus = useCallback(
    (event) => {
      if (!ref?.current) return;

      const isOutside = !ref.current.contains(event.target);
      const isCurrentlyFocused = focused.current;
      focused.current = !isOutside;

      /* NOTE: handleDocumentClick will take precedence and set focused = false
      so that both handlers won't fire, this is intentional */
      if (isOutside && isCurrentlyFocused) {
        onBlur(event);
        onFocusOutside(event);
      }
    },
    [onBlur, onFocusOutside],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    document.addEventListener('focus', handleDocumentFocus, true);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      document.removeEventListener('focus', handleDocumentFocus, true);
    };
  }, [handleDocumentClick, handleDocumentFocus]);

  return <Wrapper ref={ref}>{children}</Wrapper>;
};

FocusManager.propTypes = {
  onBlur: PropTypes.func,
  onClickOutside: PropTypes.func,
  onFocusOutside: PropTypes.func,
  wrapperComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.node.isRequired,
};

FocusManager.defaultProps = {
  onBlur: noop,
  onClickOutside: noop,
  onFocusOutside: noop,
  wrapperComponent: 'span',
};

export default FocusManager;
