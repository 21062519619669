import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon, Text, NakedButton } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import type { Option } from '../../../types';
import { mediaQuery } from 'lib/styledSystem';

const Scrollable = styled(Box)`
  max-height: 400px;
  max-width: 100%;
  overflow-y: auto;
`;

const StyledText = styled(Text)`
  font-weight: inherit;
  font-size: inherit;
  ${mediaQuery.minWidth.sm} {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const MenuItem = styled(Flex)<{ isActive: boolean }>`
  white-space: nowrap;
  cursor: pointer;
  padding-left: ${themeGet('space.4')};
  padding-right: ${themeGet('space.4')};
  border-left: 6px solid ${(props) => (props.isActive ? themeGet('colors.blue') : 'transparent')};
  &:hover {
    background-color: ${themeGet('colors.greys.porcelain')};
  }
  color: ${themeGet('colors.greys.charcoal')};
  font-size: ${themeGet('fontSize.md')};
  height: 60px;
`;

const MenuLabel = styled(Flex)`
  white-space: nowrap;
  border-bottom: 1px solid ${themeGet('colors.greys.alto')};
  color: ${themeGet('colors.greys.charcoal')};
`;

interface Props {
  selected?: Option;
  options: Array<Option>;
  menuLabel: string;
  onMenuItemClick: (event: React.MouseEvent, option: Option) => void;
  onCloseClick: () => void;
}

const SelectMenu = ({ selected, options, menuLabel, onMenuItemClick, onCloseClick }: Props) => (
  <>
    {menuLabel && (
      <MenuLabel py={[8, 4]} px={6} flexDirection="row" alignItems="center" justifyContent="space-between">
        <Text fontSize="lg">{menuLabel}</Text>
        <NakedButton onClick={onCloseClick}>
          <Icon size={24} name="close" color="icon.inclusion" />
        </NakedButton>
      </MenuLabel>
    )}
    <Scrollable>
      {options.map((option, index) => (
        <MenuItem
          key={index}
          py={2}
          alignItems="center"
          onClick={(event) => onMenuItemClick(event, option)}
          isActive={selected?.value === option.value}
        >
          <StyledText>{option.text}</StyledText>
        </MenuItem>
      ))}
    </Scrollable>
  </>
);

SelectMenu.defaultProps = {
  menuLabel: undefined,
  options: [],
  onMenuItemClick: undefined,
  onCloseClick: undefined,
};

SelectMenu.propTypes = {
  menuLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  onMenuItemClick: PropTypes.func,
  onCloseClick: PropTypes.func,
};

export default SelectMenu;
