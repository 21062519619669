import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { rem } from 'polished';

import { Flex, NakedButton, Icon } from '@qga/roo-ui/components';

const Wrapper = styled(Flex)`
  width: 100%;
  position: absolute;
  justify-content: space-between;
`;

const Button = styled(NakedButton)`
  border-radius: ${themeGet('radii.rounded')};
  background: ${themeGet('colors.white')};
  color: ${themeGet('colors.greys.steel')};

  &:hover,
  &:focus {
    outline: none;
    color: ${themeGet('colors.red')};
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    color: ${themeGet('colors.greys.alto')};
  }
`;

const CalendarNav = ({ backProps, forwardProps }) => (
  <Wrapper display={['none', 'flex']}>
    <Button data-testid="go-back-1-month" {...backProps}>
      <Icon name="chevronLeft" size={rem('32px')} />
    </Button>

    <Button data-testid="go-forward-1-month" {...forwardProps}>
      <Icon name="chevronRight" size={rem('32px')} />
    </Button>
  </Wrapper>
);

CalendarNav.propTypes = {
  backProps: PropTypes.shape().isRequired,
  forwardProps: PropTypes.shape().isRequired,
};

export default CalendarNav;
