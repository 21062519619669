import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Markup from 'components/Markup';
import { NakedButton, Box, Icon, Text } from '@qga/roo-ui/components';
import { useTooltip } from 'lib/hooks';
import { useDataLayer } from 'hooks/useDataLayer';
import { useSelector } from 'react-redux';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { getExclusiveOffer } from 'store/exclusiveOffer/exclusiveOfferSelectors';
import { Scrollable, TooltipWrapper, OfferTermsLink, FullScreenMenu, MenuLabel, NakedButtonClose } from './OfferTermsModal.style';

const OfferTerms = ({ showModal, setShowModal }) => {
  const { emitInteractionEvent } = useDataLayer();
  const {
    triggerProps: { onClick: triggerOnClick, ...rest },
    tooltipProps,
    isOpen,
  } = useTooltip('offer-terms');
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobile = isLessThanBreakpoint(0);

  const offerTermsContent = useSelector(getExclusiveOffer);

  const handleOnClick = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal, setShowModal]);

  const openTermsAndConditions = useCallback(() => {
    emitInteractionEvent({ type: 'Availability Calendar', value: 'Offer terms' });
  }, [emitInteractionEvent]);

  const triggerProps = useMemo(() => {
    const onClick = () => {
      openTermsAndConditions();
      isMobile ? handleOnClick() : triggerOnClick();
    };

    return {
      onClick,
      ...rest,
    };
  }, [handleOnClick, isMobile, openTermsAndConditions, rest, triggerOnClick]);

  return (
    <Box>
      <OfferTermsLink
        {...triggerProps}
        as={NakedButton}
        hoverColor="inherit"
        data-testid="offer-terms-button"
        aria-label="View terms and travel periods"
      >
        View terms and travel periods
        <Icon name="info" size={20} ml={1} />
      </OfferTermsLink>
      {!isMobile && isOpen && (
        <Box {...tooltipProps} zIndex="modal">
          <TooltipWrapper>
            <Markup
              content={offerTermsContent?.terms?.hotelTerms}
              mt={4}
              fontSize="base"
              color="greys.steel"
              data-testid="offer-terms-content"
            />
          </TooltipWrapper>
        </Box>
      )}
      {isMobile && showModal && (
        <FullScreenMenu data-testid="room-dropdown-select-fullscreen">
          <MenuLabel>
            <Text fontSize="base">Offer Terms and Conditions</Text>
            <NakedButtonClose onClick={handleOnClick} right={0}>
              <Icon size={20} name="close" color="icon.inclusion" />
            </NakedButtonClose>
          </MenuLabel>
          <Scrollable>
            <Markup
              content={offerTermsContent?.terms?.hotelTerms}
              mt={4}
              fontSize="base"
              color="greys.steel"
              data-testid="offer-terms-content"
            />
          </Scrollable>
        </FullScreenMenu>
      )}
    </Box>
  );
};

OfferTerms.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default OfferTerms;
