import styled from '@emotion/styled';
import { mediaQuery } from 'lib/styledSystem';
import { themeGet } from 'styled-system';
import { Box, Link, Flex, NakedButton } from '@qga/roo-ui/components';

export const Scrollable = styled(Box)`
  max-height: 100%;
  max-width: 100%;
  overflow-y: scroll;
  padding: ${themeGet('space.6')} ${themeGet('space.4')};

  ${mediaQuery.minWidth.sm} {
    max-width: 305px;
  }
`;

export const TooltipWrapper = styled(Box)`
  max-height: 400px;
  overflow-y: auto;
  box-shadow: ${themeGet('shadows.tooltip')};
  color: ${themeGet('colors.greys.steel')};
  background-color: ${themeGet('colors.white')};
  padding: ${themeGet('space.6')} ${themeGet('space.8')};
  border-radius: ${themeGet('radii.default')};
  width: ${themeGet('uiStructure.tooltipWidth')};
  text-align: left;
`;

export const OfferTermsLink = styled(Link)`
  display: inline-flex;
  align-items: center;

  color: ${themeGet('colors.greys.charcoal')};
  font-size: ${themeGet('fontSizes.sm')};
  font-weight: ${themeGet('fontWeights.normal')};
`;

export const FullScreenMenu = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.hard')};
  margin-top: ${themeGet('space.2')};
  border-radius: ${themeGet('radii.default')};
`;

export const MenuLabel = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-bottom: 1px solid ${themeGet('colors.greys.alto')};
  color: ${themeGet('colors.greys.charcoal')};
  padding: ${themeGet('space.5')} ${themeGet('space.4')};
`;

export const NakedButtonClose = styled(NakedButton)`
  position: absolute;
  right: ${themeGet('space.5')};
`;
