import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { omitProps } from '@qga/roo-ui/components';
import { hideVisually } from 'polished';
import { responsiveVariation } from 'lib/styledSystem';
import { textStyle, color, fontSize, fontWeight, letterSpacing, lineHeight, space, textAlign } from 'styled-system';

const responsiveHidden = responsiveVariation('hidden', hideVisually());

const LabelText = styled('span', omitProps(['hidden', 'color']))`
  display: inline-block;
  ${space}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${letterSpacing}
  ${lineHeight}
  ${textStyle}
  ${textAlign}
  ${responsiveHidden}
`;

LabelText.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
  ...lineHeight.propTypes,
  ...textStyle.propTypes,
  ...textAlign.propTypes,
  hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

LabelText.defaultProps = {
  hidden: false,
  mb: 1,
};

LabelText.displayName = 'LabelText';

export default LabelText;
